<script>
/***
 Name: example3
 Code: sa0ChunLuyu
 Time: 2021/10/16 09:47
 Remark: 编辑抽屉示例的抽屉组件
 */
export default {
  // 接收 显示变量 和 关闭函数
  props: ['visible', 'close','auditInfo','title','auditors','shenherenaction'],
  data() {
    return {
      Danda_acid_list_list:[],
      auditorList:[]
    }
  },
  mounted() {
    this.Danda_acid_list()
  },
  methods: {
    Danda_acid_list() {
      this.$sa0.post({
        url: this.$api('Danda_acid_list'),
        data: {
        },
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.Danda_acid_list_list = response.data.list
            //console.log(this.Get_caijilist_list);
            //this.count = Number(response.data.result.count);//分页
            //this.pagesize = Number(response.data.result.pagesize);//分页
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    // 定义 组件 自身的关闭函数
    onClose() {
      this.close();
    },
    auditCancel(){
      let check=this.auditors.filter(item=> item.password.length===0)
      if(check.length>0){
        layer.msg('请输入审核人密码')
      }else{
        this.$emit('doAuditCancel',this.auditors)
      }
    },
    audit(){

      if(this.auditInfo.auditor===0){
        layer.msg('请选择审核人姓名')
        return
      }
      if(this.auditInfo.password.length===0){
        layer.msg('请选择审核人密码')
        return
      }
      this.$emit('doAudit',this.auditInfo)
    },
    handleChange(value) {
      console.log(`selected ${value}`);
    },
  }
}
</script>
<template>
  <div>
    <!--:visible="visible"  赋值 显示变量-->
    <!--@close="onClose"    赋值 关闭函数-->
    <a-drawer :title="title" width="640" placement="right" :closable="true" :visible="visible" @close="onClose">
      <div>
        <a-row v-if="shenherenaction==='audit'">
          <a-col :span="14">
            <a-form-model>
              <a-form-model-item label="审核人姓名" :label-col="{ span: 8, offset: 0 }" :wrapper-col="{ span:15, offset: 0}">
                <a-select placeholder="请选择审核人姓名"  v-model="auditInfo.auditor">
                  <a-select-option :value="0">请选择审核人姓名</a-select-option>
                  <a-select-option v-for="(item,key) in Danda_acid_list_list" :value="item.id">{{item.name}}</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="审核人密码" :label-col="{ span: 8, offset: 0 }" :wrapper-col="{ span:15, offset: 0}">
                <a-input type="password" v-model="auditInfo.password"></a-input>
              </a-form-model-item>
              <a-form-model-item label="" :label-col="{ span: 8, offset: 0 }" :wrapper-col="{ span:15, offset: 8}">
                <a-button type="primary" @click="audit()">确定</a-button>
              </a-form-model-item>
            </a-form-model>
            </a-col>
        </a-row>
        <a-row v-else>
          <a-col :span="14">
            <a-form-model>
              <template v-for="item in auditors">
              <a-form-model-item label="审核人姓名" :label-col="{ span: 8, offset: 0 }" :wrapper-col="{ span:15, offset: 0}">
               {{item.name}}
              </a-form-model-item>
              <a-form-model-item label="审核人密码"  :label-col="{ span: 8, offset: 0 }" :wrapper-col="{ span:15, offset: 0}">
                <a-input type="password" v-model="item.password"></a-input>
              </a-form-model-item>
              </template>
              <a-form-model-item label="" :label-col="{ span: 8, offset: 0 }" :wrapper-col="{ span:15, offset: 8}">
                <a-button type="primary" @click="auditCancel()">确定</a-button>
              </a-form-model-item>
            </a-form-model>
          </a-col>
        </a-row>
<!--        <table class="layui-table">-->
<!--          <thead>-->
<!--          <tr>-->
<!--            <th>审核人姓名</th>-->
<!--            <th>审核人密码</th>-->
<!--          </tr>-->
<!--          </thead>-->
<!--          <tbody>-->
<!--          <tr v-for="(item,key) in Danda_acid_list_list">-->
<!--            <td>{{item.name}}</td>-->
<!--            <td>-->
<!--              <a-space>-->
<!--                <a-input @change="onChange"></a-input>-->
<!--                <a-button type="primary" @click="audit(item.id)">确定</a-button>-->
<!--              </a-space>-->
<!--            </td>-->
<!--          </tr>-->
<!--          </tbody>-->
<!--        </table>-->
      </div>
    </a-drawer>

  </div>
</template>
<style scoped>

</style>
