<template>
  <div>
    <a-form-model layout="inline">

      <a-row style="padding-bottom: 15px">
        <a-form-model-item label="人员信息搜索">
          <a-input class="w220" v-model="keywords" placeholder="请输入姓名、电话、身份证号" @keyup="doSearch"></a-input>
        </a-form-model-item>

        <a-form-model-item label="送样/采样信息搜索">
          <a-input v-model="SCinfo" class="w220" placeholder="请输入送检单位/采样人联系电话" @keyup="doSearch"></a-input>
        </a-form-model-item>
      </a-row>

      <a-row style="padding-bottom: 15px">
        <a-form-model-item label="送样/采样时间">
          <a-space>
            <a-date-picker v-model="SCtime_start" @change="doDateChange" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD"/>
            -
            <a-date-picker v-model="SCtime_end" @change="doDateChange" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD"/>
          </a-space>
        </a-form-model-item>

        <a-form-model-item label="检验时间">
          <a-space>
            <a-date-picker v-model="JYtime_start" @change="doDateChange" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD"/>
            -
            <a-date-picker v-model="JYtime_end" @change="doDateChange" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD"/>
          </a-space>
        </a-form-model-item>
      </a-row>

      <a-row style="padding-bottom: 15px">
        <a-form-model-item label="审核时间">
          <a-space>
            <a-date-picker v-model="SHtime_start" @change="doDateChange" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD"/>
            -
            <a-date-picker v-model="SHtime_end" @change="doDateChange" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD"/>
          </a-space>
        </a-form-model-item>
        <a-form-model-item label="检验/审核人">
          <a-input class="w160" v-model="SHinfo" placeholder="请检验人或审核人姓名" @keyup="doSearch"></a-input>
        </a-form-model-item>

        <a-form-model-item label="">
          <a-button type="primary" @click="clearCondition">清除搜索</a-button>
        </a-form-model-item>

        <a-form-model-item label="">
          <a-button type="primary" @click="printChecked">打印选中报告</a-button>
        </a-form-model-item>
      </a-row>

    </a-form-model>

    <table class="layui-table" lay-size="sm" style="margin-top: 25px">
      <thead>
      <tr>
        <th>
          <a-checkbox @click="checkAll"></a-checkbox>
        </th>
        <th>检测名称/批次</th>
        <th>样本号</th>
        <th>性别</th>
        <th>姓名</th>
        <th>身份证</th>
        <th>手机号</th>
        <th>送样日期</th>
        <th>接收时间</th>
        <th>完成检测时间</th>
        <th>送检单位</th>
        <th>人员类别</th>
        <th>结果</th>
        <th>送检人</th>
        <th>检验人</th>
        <th>审核人</th>
        <th>审核时间</th>
        <!--        <th>操作</th>-->
      </tr>
      </thead>
      <tbody>
      <tr v-for="item in list" :key="item.id">
        <td>
          <a-checkbox v-model="item.checked"></a-checkbox>
        </td>
        <td>{{ item.identifier }}</td>
        <td>标本{{ item.group_id }}号</td>
        <td>{{ item.sex }}</td>
        <td>{{ item.username }}</td>
        <td>{{ item.id_card }}</td>
        <td>{{ item.phone }}</td>
        <td>{{ item.add_date }}</td>
        <td>{{ item.create_time }}</td>
        <td>{{ item.check_time }}</td>
        <td>{{ item.unit }}</td>
        <td>{{ item.category }}</td>
        <td>
          <span style="color:blue;" v-if="item.result===1">阴性</span>
          <span style="color:red;" v-if="item.result===2">阳性</span>
        </td>
        <td>{{ item.contacts_name }}</td>
        <td>{{ item.checker }}</td>
        <td>{{ item.auditor }}</td>
        <td>{{ item.audit_time }}</td>
        <!--        <td>-->
        <!--          <a-button type="primary" @click="printIt(item.id)">打印报告</a-button>-->
        <!--        </td>-->
      </tr>
      </tbody>
    </table>
    <div>
      <a-pagination v-if="list.length>0" v-model="pagination.current" :total="pagination.total"
                    :pageSize="pagination.pageSize" show-less-items @change="onChange"/>
    </div>

    <Shenheren :close="onCloseDrawerShenheren" :visible="drawer_visible"></Shenheren>
  </div>

</template>

<script>
import {hs_print} from "../../../utils/hs_print";
import Shenheren from "./shenheren/shenheren.vue";

export default {
  name: "hesuanjeiguo",
  components: {
    Shenheren
  },

  data() {
    return {
      keywords: "",
      SCinfo: '',
      SCtime_start: '',
      SCtime_end: '',
      JYtime_start: '',
      JYtime_end: '',
      SHtime_start: '',
      SHtime_end: '',
      SHinfo: '',
      list: [],
      // 定义控制抽屉显示的变量
      drawer_visible: false,
      pagination: {
        'current': 1,
        'total': 30,
        'pageSize': 30
      },
    };
  },
  mounted() {
    this.doSearch()
  },
  created() {
  },
  methods: {
    // 定义 关闭抽屉时的 审核人员列表
    showDrawerShenheren() {
      this.drawer_visible = true;
    },
    onCloseDrawerShenheren() {
      this.drawer_visible = false;
    },
    onChange(page) {
      this.doSearch(page)
    },
    printIt(id) {
      hs_print([id])
    },
    printChecked() {
      let checked = this.list.filter(item => {
        if (item.checked) {
          return true
        }
      })
      if (checked.length === 0) {
        layer.msg('未选中任何要打印的报告单')
      } else {
        let ids = checked.map(item => item.id)
        hs_print(ids)
      }
    },
    clearCondition() {
      this.keywords = ''
      this.SCinfo = ''
      this.SCtime_start = ''
      this.SCtime_end = ''
      this.JYtime_start = ''
      this.JYtime_end = ''
      this.SHtime_start = ''
      this.SHtime_end = ''
      this.SHinfo = ''
      this.list = []
      this.doSearch()
    },
    checkAll() {
      this.list = this.list.map(item => {
        item.checked = !item.checked
        return item
      })
    },
    doDateChange(e){
      this.doSearch(1)
    },
    doSearch(page = 1) {
      // if(this.keywords.length===0){
      //   this.list=[]
      //   return
      // }
      let param = {
        keywords: this.keywords,
        keywords_info: this.SCinfo,
        add_date_start: this.SCtime_start,
        add_date_end: this.SCtime_end,
        check_time_start: this.JYtime_start,
        check_time_end: this.JYtime_end,
        audit_time_start: this.SHtime_start,
        audit_time_end: this.SHtime_end,
        person_info: this.SHinfo,
        page: page
      }
      this.$sa0.file({
        url: this.$api('核酸检查询结果'),
        data: param,

      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.pagination.current = response.data.pagination.current
            this.pagination.total = response.data.pagination.total
            this.pagination.pageSize = response.data.pagination.pageSize
            this.list = response.data.list.map(item => {
              item.checked = false
              return item
            })

          },
          error: (res) => {

          }
        })
      })
    }
  },
}
</script>

<style scoped>
  .layui-table th{ text-align: left}
.w350 {
  width: 350px
}

.w220 {
  width: 230px
}

.w160 {
  width: 160px
}

</style>
