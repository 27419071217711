// 打印这些核酸报告
import api from '../api/api'
import sa0 from '../sa0/sa0'

export const hs_print = (ids) => {
    sa0.post({
        url: api('打印这些核酸报告'), data: {ids}
    }).then((response) => {
        sa0.response({
            response: response.data, then: (response) => {
                let url = response.data.pdf_url
                printJS({printable: url, type: 'pdf'});// 解决跨域
            }, error: (response) => {
                layer.msg(response.message)
            },
        })
    })
}
